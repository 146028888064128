import React from 'react';
import { Box, Typography } from '@mui/material';

export default function PhaseThreeLanding() {
	return (
		<Box className='sliding-up'>
			<Box
				borderRadius={3}
				sx={{ backgroundColor: 'rgba(20,36,32, 0.95)', color: 'white' }}
				padding={5}
			>
				<Typography
					variant='h3'
					align='center'
					fontFamily={'Permanent Marker, cursive'}
				>
					hello world
				</Typography>
				<Box pb={5}>
					<Box sx={{ textAlign: 'center' }}>
						<Typography mt={5} fontSize={'large'}>
							exciting things are in the works, stay tuned
						</Typography>
						<Typography mt={1}>-Creatorship™</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
