import React from 'react';
import { Box } from '@mui/material';
import ContactUs from '../connect-sections/contact-us/ContactUs';

export default function Contact() {
	return (
		<Box className='sliding-up' sx={{ marginTop: '30px' }}>
			<ContactUs />
		</Box>
	);
}
