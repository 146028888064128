import React from 'react';

export default function Terms() {
	return (
		<>
			<div
				style={{
					backgroundColor: 'white',
					padding: '15px',
					paddingBottom: '25px',
				}}
			>
				<h1
					style={{
						textAlign: 'center',
					}}
				>
					TERMS OF USE OF CREATORSHIP.BIZ
				</h1>
				<h3
					style={{
						textAlign: 'center',
					}}
				>
					August 10, 2022
				</h3>

				<div>
					<ol>
						{/* 1 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>BINDING EFFECT</p>
							<p style={{ margin: '0' }}>
								This is a binding agreement. By using the Internet site
								located at www.creatorship.biz (the “Site”) or any
								services provided in connection with the Site (the
								“Service” or “Services”), you agree to abide by these
								Terms of Use, as they may be amended by Creatorship,
								LLC, a California Limited Liability Company (“Company”)
								from time to time in its sole discretion. Company will
								post a notice on the Site any time these Terms of Use
								have been changed or otherwise updated. It is your
								responsibility to review these Terms of Use
								periodically, and if at any time you find these Terms of
								Use unacceptable, you must immediately leave the Site
								and cease all use of the Service and the Site. YOU AGREE
								THAT BY USING THE SERVICE YOU REPRESENT THAT YOU ARE AT
								LEAST 18 YEARS OLD AND THAT YOU ARE LEGALLY ABLE TO
								ENTER INTO THIS AGREEMENT.
							</p>
						</li>

						{/* 2 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>PRIVACY POLICY</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								Company respects your privacy and permits you to control
								the treatment of your personal information. A complete
								statement of Company’s current privacy policy can be
								found by clicking
								<span style={{ marginLeft: '5px' }}>
									<a href='/privacy'>here</a>
								</span>
								. Company’s privacy policy is expressly incorporated
								into this Agreement by this reference.
							</p>
							<p style={{ margin: '0' }}>
								When you are required to open an account to use or
								access the Site or Service, you must complete the
								registration process by providing the complete and
								accurate information requested on the registration form.
								You will also be asked to provide a user name and
								password. You are entirely responsible for maintaining
								the confidentiality of your password. You may not use
								the account, username, or password of someone else at
								any time. You agree to notify Company immediately on any
								unauthorized use of your account, user name, or
								password. Company shall not be liable for any loss that
								you incur as a result of someone else using your
								password, either with or without your knowledge. You may
								be held liable for any losses incurred by Company, its
								affiliates, officers, directors, employees, consultants,
								agents, and representatives due to someone else’s use of
								your account or password.
							</p>
						</li>

						{/* 3 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>USE OF SOFTWARE</p>
							<p style={{ margin: '0' }}>
								Company may make certain software available to you from
								the Site. If you download software from the Site, the
								software, including all files and images contained in or
								generated by the software, and accompanying data
								(collectively, “Software”) are deemed to be licensed to
								you by Company, for your personal, noncommercial, home
								use only. Company does not transfer either the title or
								the intellectual property rights to the Software, and
								Company retains full and complete title to the Software
								as well as all intellectual property rights therein. You
								may not sell, redistribute, or reproduce the Software,
								nor may you decompile, reverse-engineer, disassemble, or
								otherwise convert the Software to a human-perceivable
								form. All trademarks and logos are owned by Company or
								its licensors and you may not copy or use them in any
								manner.
							</p>
						</li>

						{/* 4 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>USER CONTENT</p>
							<p style={{ margin: '0' }}>
								You grant Company a license to use the materials you
								provide to Creatorshp, LLC or post yourself to the Site
								or Service. By providing, posting, downloading,
								displaying, performing, transmitting, or otherwise
								distributing information or other content (“User
								Content”) to the Site or Service, you are granting
								Company, its affiliates, officers, directors, employees,
								consultants, agents, and representatives a license to
								use User Content in connection with the operation of the
								Internet business of Company, its affiliates, officers,
								directors, employees, consultants, agents, and
								representatives, including without limitation, a right
								to copy, distribute, transmit, publicly display,
								publicly perform, reproduce, edit, translate, and
								reformat User Content. You will not be compensated for
								any User Content. You agree that Company may publish or
								otherwise disclose your name in connection with your
								User Content. By posting User Content on the Site or
								Service, you warrant and represent that you own the
								rights to the User Content or are otherwise authorized
								to post, distribute, display, perform, transmit, or
								otherwise distribute User Content.
							</p>
						</li>

						{/* 5 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								USER SUGGESTIONS: SUBMISSIONS
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								Any feedback, comments, ideas, improvements or
								suggestions (collectively, "Suggestions") provided by
								you to Company with respect to the website shall remain
								the sole and exclusive property of Company. Company
								shall be free to use, copy, modify, publish, or
								redistribute the Suggestions for any purpose and in any
								way without any credit or any compensation to you.
							</p>
							<p style={{ margin: '0' }}>
								In the event that you submit or post any ideas, creative
								suggestions, designs, photographs, information,
								advertisements, data or proposals, including ideas for
								new or improved products, services, features,
								technologies or promotions, you expressly agree that
								such submissions will automatically be treated as
								non-confidential and non-proprietary and will become the
								sole property of Company without any compensation or
								credit to you whatsoever. Company and its affiliates
								shall have no obligations with respect to such
								submissions or posts and may use the ideas contained in
								such submissions or posts for any purposes in any medium
								in perpetuity, including, but not limited to,
								developing, manufacturing, and marketing products and
								services using such ideas.
							</p>
						</li>

						{/* 6 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								COMPLIANCE WITH INTELLECTUAL PROPERTY LAWS
							</p>
							<p style={{ margin: '0' }}>
								When accessing the Site or using the Service, you agree
								to obey the law and to respect the intellectual property
								rights of others. Your use of the Service and the Site
								is at all times governed by and subject to laws
								regarding copyright ownership and use of intellectual
								property. You agree not to upload, download, display,
								perform, transmit, or otherwise distribute any
								information or content (collectively, “Content”) in
								violation of any third party’s copyrights, trademarks,
								or other intellectual property or proprietary rights.
								You agree to abide by laws regarding copyright ownership
								and use of intellectual property, and you shall be
								solely responsible for any violations of any relevant
								laws and for any infringements of third party rights
								caused by any Content you provide or transmit, or that
								is provided or transmitted using your User ID. The
								burden of proving that any Content does not violate any
								laws or third party rights rests solely with you.
							</p>
						</li>

						{/* 7 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>INAPPROPRIATE CONTENT</p>
							<p style={{ margin: '0' }}>
								You shall not make the following types of Content
								available. You agree not to upload, download, display,
								perform, transmit, or otherwise distribute any Content
								that (a) is libelous, defamatory, obscene, pornographic,
								abusive, or threatening; (b) advocates or encourages
								conduct that could constitute a criminal offense, give
								rise to civil liability, or otherwise violate any
								applicable local, state, national, or foreign law or
								regulation; or (c) advertises or otherwise solicits
								funds or is a solicitation for goods or services.
								Company reserves the right to terminate your receipt,
								transmission, or other distribution of any such material
								using the Service, and, if applicable, to delete any
								such material from its servers. Company intends to
								cooperate fully with any law enforcement officials or
								agencies in the investigation of any violation of these
								Terms of Use or of any applicable laws.
							</p>
						</li>

						{/* 8 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								COPYRIGHT INFRINGEMENT
							</p>
							<p style={{ margin: '0' }}>
								Company has in place certain legally mandated procedures
								regarding allegations of copyright infringement
								occurring on the Site or with the Service. Company has
								adopted a policy that provides for the immediate
								suspension and/or termination of any Site or Service
								user who is found to have infringed on the rights of
								Company or of a third party, or otherwise violated any
								intellectual property laws or regulations. Company’s
								policy is to investigate any allegations of copyright
								infringement brought to its attention. If you have
								evidence, know, or have a good faith belief that your
								rights or the rights of a third party have been violated
								and you want Company to delete, edit, or disable the
								material in question, you must provide Company with all
								of the following information: (a) a physical or
								electronic signature of a person authorized to act on
								behalf of the owner of the exclusive right that is
								allegedly infringed; (b) identification of the
								copyrighted work claimed to have been infringed, or, if
								multiple copyrighted works are covered by a single
								notification, a representative list of such works; (c)
								identification of the material that is claimed to be
								infringed or to be the subject of infringing activity
								and that is to be removed or access to which is to be
								disabled, and information reasonably sufficient to
								permit Company to locate the material; (d) information
								reasonably sufficient to permit Company to contact you,
								such as an address, telephone number, and if available,
								an electronic mail address at which you may be
								contacted; (e) a statement that you have a good faith
								belief that use of the material in the manner complained
								of is not authorized by the copyright owner, its agent,
								or the law; and (f) a statement that the information in
								the notification is accurate, and under penalty of
								perjury, that you are authorized to act on behalf of the
								owner of an exclusive right that is allegedly infringed.
								For this notification to be effective, you must provide
								it to Company’s designated agent at:
							</p>
							<p style={{ paddingLeft: '15px' }}>
								<p style={{ margin: '0' }}>Creatorship</p>
								<p style={{ margin: '0' }}>360 E. 1st Street #4183</p>
								<p style={{ margin: '0' }}>Tustin, CA 92780</p>
								<p style={{ margin: '0' }}>(714) 464-7131</p>
								<p style={{ margin: '0' }}>admin@creatorship.biz</p>
							</p>
						</li>

						{/* 9 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>ALLEGED VIOLATIONS</p>
							<p style={{ margin: '0' }}>
								Company reserves the right to terminate your use of the
								Service and/or the Site. To ensure that Company provides
								a high quality experience for you and for other users of
								the Site and the Service, you agree that Company or its
								representatives may access your account and records on a
								case-by-case basis to investigate complaints or
								allegations of abuse, infringement of third party
								rights, or other unauthorized uses of the Site or the
								Service. Company does not intend to disclose the
								existence or occurrence of such an investigation unless
								required by law, but Company reserves the right to
								terminate your account or your access to the Site
								immediately, with or without notice to you, and without
								liability to you, if Company believes that you have
								violated any of the Terms of Use, furnished Company with
								false or misleading information, or interfered with use
								of the Site or the Service by others.
							</p>
						</li>

						{/* 10 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>NO WARRANTIES</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								<span
									style={{ fontStyle: 'italic', marginRight: '5px' }}
								>
									COMPANY HEREBY DISCLAIMS ALL WARRANTIES.
								</span>
								COMPANY IS MAKING THE SITE AVAILABLE “AS IS” WITHOUT
								WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL
								DAMAGE OR LOSS FROM USE OF, OR INABILITY TO USE, THE
								SITE OR THE SERVICE. TO THE MAXIMUM EXTENT PERMITTED BY
								LAW, COMPANY EXPRESSLY DISCLAIMS ANY AND ALL
							</p>
							<p style={{ margin: '0' }}>
								WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SITE,
								INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF
								MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
								NONINFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE SITE
								OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR THAT THE
								OPERATION OF THE SITE OR THE SERVICE WILL BE
								UNINTERRUPTED OR ERROR-FREE.
							</p>
						</li>

						{/* 11 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								LIMITED LIABILITY.
								<span
									style={{ fontStyle: 'italic', marginLeft: '5px' }}
								>
									COMPANY’S LIABILITY TO YOU IS LIMITED.
								</span>
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								Notwithstanding any damages that you might incur, the
								entire liability of Company and any of its its
								affiliates, officers, directors, employees, attorneys,
								consultants, agents, suppliers and representatives under
								any provision of these Terms of Use and your exclusive
								remedy for all of the foregoing shall be limited to the
								amount actually paid by you for the website.
							</p>
							<p style={{ margin: '0' }}>
								TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT
								SHALL COMPANY BE LIABLE FOR DAMAGES OF ANY KIND
								(INCLUDING, BUT NOT LIMITED TO, SPECIAL, INCIDENTAL, OR
								CONSEQUENTIAL DAMAGES, LOST PROFITS, OR LOST DATA,
								REGARDLESS OF THE FORESEEABILITY OF THOSE DAMAGES)
								ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE
								SITE OR ANY OTHER MATERIALS OR SERVICES PROVIDED TO YOU
								BY COMPANY. This limitation shall apply regardless of
								whether the damages arise out of breach of contract,
								tort, or any other legal theory or form of action.
							</p>
						</li>

						{/* 12 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								AFFILIATED SITES OR LINKS TO OTHER SITES
							</p>
							<p style={{ margin: '0' }}>
								Company has no control over, and no liability for any
								third party websites or materials. Company works with a
								number of partners and affiliates whose Internet sites
								may be linked with the Site. Because neither Company nor
								the Site has control over the content and performance of
								these partner and affiliate sites, Company makes no
								guarantees about the accuracy, currency, content, or
								quality of the information provided by such sites, and
								Company assumes no responsibility for unintended,
								objectionable, inaccurate, misleading, or unlawful
								content that may reside on those sites. Similarly, from
								time to time in connection with your use of the Site,
								you may have access to content items (including, but not
								limited to, websites) that are owned by third parties.
								You acknowledge and agree that Company makes no
								guarantees about, and assumes no responsibility for, the
								accuracy, currency, content, or quality of this third
								party content, and that, unless expressly provided
								otherwise, these Terms of Use shall govern your use of
								any and all third party content.
							</p>
						</li>

						{/* 13 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>COOKIES</p>
							<p style={{ margin: '0' }}>
								Company may use "Cookies" to identify the areas of our
								website that you have visited. A Cookie is a small piece
								of data stored on your computer or mobile device by your
								web browser. We may use Cookies to enhance the
								performance and functionality of our website but are
								non-essential to their use. However, without these
								cookies, certain functionality like videos may become
								unavailable or you would be required to enter your login
								details every time you visit the website as we would not
								be able to remember that you had logged in previously.
								Most web browsers can be set to disable the use of
								Cookies. However, if you disable Cookies, you may not be
								able to access functionality on our website correctly or
								at all. We never place Personally Identifiable
								Information in Cookies, however, we have no control over
								any third party’s use of Cookies or Cookies that are
								embedded in You Tube videos which you may access from
								our site.
							</p>
						</li>

						{/* 14 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>THIRD-PARTY SERVICES</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								We may display, include or make available third-party
								content (including data, information, applications and
								other products services) or provide links to third-party
								websites or services ("Third-Party Services").
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								You acknowledge and agree that Company shall not be
								responsible for any Third-Party Services, including
								their accuracy, completeness, timeliness, validity,
								copyright compliance, legality, decency, quality or any
								other aspect thereof. Company does not assume and shall
								not have any liability or responsibility to you or any
								other person or entity for any Third-Party Services.
							</p>
							<p style={{ margin: '0' }}>
								Third-Party Services and links thereto are provided
								solely as a convenience to you and you access and use
								them entirely at your own risk.
							</p>
						</li>

						{/* 15 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>PROMOTIONS</p>
							<p style={{ margin: '0' }}>
								Company may, from time to time, include contests,
								promotions, sweepstakes, or other activities
								(“Promotions”) that require you to submit material or
								information concerning yourself. Please note that all
								Promotions may be governed by separate rules that may
								contain certain eligibility requirements, such as
								restrictions as to age and geographic location. You are
								responsible to read all Promotions rules to determine
								whether or not you are eligible to participate. If you
								enter any Promotion, you agree to abide by and to comply
								with all Promotions Rules.
							</p>
						</li>

						{/* 16 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>PROHIBITED USES</p>
							<p style={{ margin: '0' }}>
								Company imposes certain restrictions on your permissible
								use of the Site and the Service. You are prohibited from
								violating or attempting to violate any security features
								of the Site or Service, including, without limitation,
								(a) accessing content or data not intended for you, or
								logging onto a server or account that you are not
								authorized to access; (b) attempting to probe, scan, or
								test the vulnerability of the Service, the Site, or any
								associated system or network, or to breach security or
								authentication measures without proper authorization;
								(c) interfering or attempting to interfere with service
								to any user, host, or network, including, without
								limitation, by means of submitting a virus to the Site
								or Service, overloading, “flooding,” “spamming,” “mail
								bombing,” or “crashing;” (d) using the Site or Service
								to send unsolicited e-mail, including, without
								limitation, promotions, or advertisements for products
								or services; (e) forging any TCP/IP packet header or any
								part of the header information in any e- mail or in any
								posting using the Service; or (f) attempting to modify,
								reverse-engineer, decompile, disassemble, or otherwise
								reduce or attempt to reduce to a human-perceivable form
								any of the source code used by Company in providing the
								Site or Service. Any violation of system or network
								security may subject you to civil and/or criminal
								liability.
							</p>
						</li>

						{/* 17 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>INDEMNITY</p>
							<p style={{ margin: '0' }}>
								You agree to indemnify Company for certain of your acts
								and omissions. You agree to indemnify, defend, and hold
								harmless Company, its affiliates, officers, directors,
								employees, attorneys, consultants, agents, and
								representatives from any and all third party claims,
								losses, liability, damages, and/or costs (including
								reasonable attorney fees and costs) arising from your
								access to or use of the Site, your violation of these
								Terms of Use, or your infringement, or infringement by
								any other user of your account, of any intellectual
								property or other right of any person or entity. Company
								will notify you promptly of any such claim, loss,
								liability, or demand, and will provide you with
								reasonable assistance, at your expense, in defending any
								such claim, loss, liability, damage, or cost.
							</p>
						</li>

						{/* 18 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>COPYRIGHT</p>
							<p style={{ margin: '0' }}>
								All contents of Site or Service are: Copyright © 2022
								Creatorship, LLC, 360 E. 1st Street #4183, Tustin, CA
								92780. All rights reserved.
							</p>
						</li>

						{/* 19 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>TERM AND TERMINATION</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								These Terms of Use shall remain in effect until
								terminated by you or Company.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								Company may, in its sole discretion, at any time and for
								any or no reason, suspend or terminate these Terms of
								Use with or without prior notice.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								These Terms of Use will terminate immediately, without
								prior notice from Company, in the event that you fail to
								comply with any provision of these Terms of Use. You may
								also terminate these Terms of Use by deleting the
								website and all copies thereof from your computer.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								Upon termination of these Terms of Use, you shall cease
								all use of the website and delete all copies of the
								website from your computer.
							</p>
							<p style={{ margin: '0' }}>
								Termination of these Terms of Use will not limit any of
								Company's rights or remedies at law or in equity in case
								of breach by you (during the term of these Terms of Use)
								of any of your obligations under the present Terms of
								Use.
							</p>
						</li>

						{/* 20 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								GOVERNING LAW; JURISDICTION; VENUE
							</p>
							<p style={{ margin: '0' }}>
								These Terms of Use shall be construed in accordance with
								and governed by the laws of the United States and the
								State of California, without reference to their rules
								regarding conflicts of law. You hereby irrevocably
								consent to the exclusive jurisdiction of the state or
								federal courts in Orange County, California, USA in all
								disputes arising out of or related to the use of the
								Site or Service.
							</p>
						</li>

						{/* 21 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								MERGER; SEVERABILITY; WAIVER; LIMITATION OF ACTIONS
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								These Terms of Use, together with the Privacy Policy and
								any other legal notices published by Company on the
								website shall constitute the entire agreement between
								you and Company concerning the website or the Services
								provided therein. These Terms of Use supersede all prior
								and contemporaneous written or oral agreements between
								you and Company.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								If, for whatever reason, a court of competent
								jurisdiction finds any term or condition in these Terms
								of Use to be unenforceable or invalid, such provision
								will be changed and interpreted to accomplish the
								objectives of such provision to the greatest extent
								possible under applicable law and all other terms and
								conditions will remain unaffected and in full force and
								effect.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								No waiver of any breach of any provision of these Terms
								of Use shall constitute a waiver of any prior,
								concurrent, or subsequent breach of the same or any
								other provisions hereof, and no waiver shall be
								effective unless made in writing and signed by an
								authorized representative of the waiving party.
							</p>
							<p style={{ margin: '0' }}>
								YOU AND COMPANY AGREE THAT ANY CAUSE OF ACTION ARISING
								OUT OF OR RELATED TO THE WEBSITE OR SERVICES PROVIDED
								THEREIN MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE
								CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION
								IS PERMANENTLY BARRED.
							</p>
						</li>

						{/* 22 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>NO LICENSE</p>
							<p style={{ margin: '0' }}>
								Nothing contained on the Site should be understood as
								granting you a license to use any of the trademarks,
								service marks, or logos owned by Company or by any third
								party.
							</p>
						</li>

						{/* 23 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>CALIFORNIA USE ONLY</p>
							<p style={{ margin: '0' }}>
								The Site is controlled and operated by Company from its
								offices in the State of California. Company makes no
								representation that any of the materials or the services
								to which you have been given access are available or
								appropriate for use in other locations. Your use of or
								access to the Site should not be construed as Company’s
								purposefully availing itself of the benefits or
								privilege of doing business in any state or jurisdiction
								other than California.
							</p>
						</li>

						{/* 24 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>MODIFICATIONS</p>
							<p style={{ margin: '0' }}>
								Company may, in its sole discretion and without prior
								notice, (a) revise these Terms of Use; (b) modify the
								Site and/or the Service; and (c) discontinue the Site
								and/or Service at any time. Company shall post any
								revision to these Terms of Use to the Site, and the
								revision shall be effective immediately on such posting.
								You agree to review these Terms of Use and other online
								policies posted on the Site periodically to be aware of
								any revisions. You agree that, by continuing to use or
								access the Site following notice of any revision, you
								shall abide by any such revision.
							</p>
						</li>

						{/* 25 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>TYPOGRAPHICAL ERRORS</p>
							<p style={{ margin: '0' }}>
								In the event a product and/or service is listed at an
								incorrect price or with incorrect information due to
								typographical error, we shall have the right to refuse
								or cancel any orders placed for the product and/or
								service listed at the incorrect price. We shall have the
								right to refuse or cancel any such order whether or not
								the order has been confirmed and your credit card
								charged. If your credit card has already been charged
								for the purchase and your order is canceled, we shall
								immediately issue a credit to your credit card account
								or other payment account in the amount of the charge.
							</p>
						</li>

						{/* 26 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								NOTICE OF DISPUTE; BINDING ARBITRATION; ATTORNEY’S FEES
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								This section applies to any dispute EXCEPT IT DOESN’T
								INCLUDE A DISPUTE RELATING TO CLAIMS FOR INJUNCTIVE OR
								EQUITABLE RELIEF REGARDING THE ENFORCEMENT OR VALIDITY
								OF YOUR OR COMPANY’S INTELLECTUAL PROPERTY RIGHTS. The
								term “dispute” means any dispute, action, or other
								controversy between you and Company concerning the
								Services or this agreement, whether in contract,
								warranty, tort, statute, regulation, ordinance, or any
								other legal or equitable basis. “Dispute” will be given
								the broadest possible meaning allowable under law.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								<span
									style={{
										textDecoration: 'underline',
										marginRight: '5px',
									}}
								>
									Notice of Dispute.
								</span>
								In the event of a dispute, you or Company must give the
								other a “Notice of Dispute,” which is a written
								statement that sets forth the name, address, and contact
								information of the party giving it, the facts giving
								rise to the dispute, and the relief requested. You must
								send any Notice of Dispute via email to:
								info@creatorship.biz. Company will send any Notice of
								Dispute to you by mail to your address if we have it, or
								otherwise to your email address. You and Company will
								attempt to resolve any dispute through informal
								negotiation within sixty (60) days from the date the
								Notice of Dispute is sent. After sixty (60) days, you or
								Company may commence arbitration.
							</p>
							<p style={{ margin: '0', marginBottom: '15px' }}>
								<span
									style={{
										textDecoration: 'underline',
										marginRight: '5px',
									}}
								>
									Binding Arbitration.
								</span>
								If you and Company don’t resolve any dispute by informal
								negotiation, any other effort to resolve the dispute
								will be conducted exclusively by binding arbitration as
								described in this section. You are giving up the right
								to litigate (or participate in as a party or class
								member) all disputes in court before a judge or jury.
								The dispute shall be settled by binding arbitration in
								accordance with the arbitration rules of JAMS
								(www.jamsadr.com) in the County of Orange, California.
								Either party may seek any interim or preliminary
								injunctive relief from any court of competent
								jurisdiction in Orange County, California, as necessary
								to protect the party’s rights or property pending the
								completion of arbitration.
							</p>
							<p style={{ margin: '0' }}>
								<span
									style={{
										textDecoration: 'underline',
										marginRight: '5px',
									}}
								>
									Attorney's Fees.
								</span>
								In any action, litigation, arbitration or other
								proceeding between the parties arising out of or in
								relation to this Agreement, the prevailing party in such
								action shall be awarded, in addition to any damages,
								injunctions or other relief, and without regard to
								whether or not such matter be prosecuted to final
								judgment, such party’s costs and expenses, including but
								not limited to taxable costs and reasonable attorneys’,
								accountants’ and experts’ fees and costs.
							</p>
						</li>

						{/* 27 */}
						<li>
							<p style={{ fontWeight: 'bold' }}>ACKNOWLEDGEMENT</p>
							<p style={{ margin: '0' }}>
								BY USING THE SERVICE OR ACCESSING THE SITE, YOU
								ACKNOWLEDGE THAT YOU HAVE READ THESE TERMS OF USE AND
								AGREE TO BE BOUND BY THEM.
							</p>
						</li>
					</ol>
				</div>
			</div>
		</>
	);
}
