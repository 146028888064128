import React, { useEffect } from 'react';
import './App.css';
import './utils/style/animations.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import ReactGa from 'react-ga';
import MainNav from './components/MainNav';

function App() {
	const theme = createTheme({
		typography: {
			fontFamily: 'Fira Sans Condensed, sans-serif',
		},
	});

	const analyze = async () => {
		ReactGa.initialize('G-T5S5C95F5Y');
		ReactGa.pageview(window.location.pathname);
	};

	const initialize = () => {
		analyze();
	};

	useEffect(() => {
		initialize();
		// eslint-disable-next-line
	}, []);

	return (
		<div className='App'>
			<ThemeProvider theme={theme}>
				<MainNav />
			</ThemeProvider>
		</div>
	);
}

export default App;
