import React from 'react';

export default function PrivacyPolicy() {
	return (
		<>
			<div
				style={{
					backgroundColor: 'white',
					padding: '15px',
					paddingBottom: '25px',
				}}
			>
				<h1
					style={{
						textAlign: 'center',
					}}
				>
					PRIVACY POLICY
				</h1>
				<h3
					style={{
						textAlign: 'center',
					}}
				>
					EFFECTIVE DATE: August 10, 2022
				</h3>

				<div>
					<ol>
						{/* 1. INTRODUCTION */}
						<li>
							<p style={{ fontWeight: 'bold' }}>INTRODUCTION</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										1.1 PURPOSE OF POLICY
									</p>
									<p style={{ margin: '0' }}>
										Creatorship, LLC, a California Limited Liability
										Company ("us," "we," or "Company") is committed to
										respecting the privacy rights of its customers,
										visitors, and other users of the Company Website
										and services (together, the "Site"). We created
										this Privacy Policy (this "Policy") to give you
										information about our collection, use, and
										disclosure of information about you, as well as
										your rights regarding that information. This
										Policy is only applicable to the Site and any
										offline location where we make this Policy
										available to you, and not to any other websites
										that you may be able to access from the Site, each
										of which may have data collection and use
										practices and policies that differ materially from
										this Policy.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										1.2 NOTICE CONCERNING CHILDREN
									</p>
									<p style={{ margin: '0' }}>
										PLEASE NOTE: We are a general audience site, and
										do not direct any of our content specifically at
										children under 13 years of age. We understand and
										are committed to respecting the sensitive nature
										of children's privacy online. If we learn or have
										reason to suspect that a Site user is under age
										13, we will promptly delete any personal
										information in that user's account.
									</p>
								</li>
							</ul>
						</li>

						{/* 2. INFORMATION COLLECTION PRACTICES */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								INFORMATION COLLECTION PRACTICES
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										2.1 WHAT INFORMATION DOES THE COMPANY COLLECT?
									</p>
									<ol type='a'>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												INFORMATION YOU PROVDE
											</p>
											<p style={{ margin: '0' }}>
												In operating the Site, Company may include
												registration, online surveys, and other
												online forms that ask users to provide their
												names, e-mail addresses, and other contact
												information, as well as the following
												categories of information:
												<ul>
													<li>
														Social media posts and account pages
													</li>
													<li>
														Contact preferences and networking
														goals
													</li>
													<li>
														Upcoming social media posts and
														promotions prior to their release to
														the general public
													</li>
												</ul>
												Depending on your use of the Site and our
												future site development, we may also request
												financial data (such as your account or
												credit card number for purchases) and
												demographic data (such as your zip code,
												age, and income). If you communicate with us
												by e-mail, post messages to any of our chat
												groups, bulletin boards, or forums, or
												otherwise complete online forms, surveys, or
												contest entries, any information provided in
												those communications will be collected from
												you.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												AUTOMATIC COLLECTION
											</p>
											<p style={{ margin: '0' }}>
												When you visit the Site, our servers
												automatically recognize (1) visitors' domain
												names; (2) IP addresses (the number assigned
												to computers on the Internet); (3) device
												information; and (4) location data. This
												"traffic data" may be used for marketing
												purposes or for improving the services we
												offer, as explained in more detail below.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												TRACKING TECHNOLOGIES
											</p>
											<p style={{ margin: '0' }}>
												Company may use "Cookies" and other tracking
												technologies to identify the areas of our
												website that you have visited. A Cookie is a
												small piece of data stored on your computer
												or mobile device by your web browser. We may
												use Cookies to enhance the performance and
												functionality of our website but are
												non-essential to their use. However, without
												these cookies, certain functionality like
												videos may become unavailable or you would
												be required to enter your login details
												every time you visit the website as we would
												not be able to remember that you had logged
												in previously. Most web browsers can be set
												to disable the use of Cookies. However, if
												you disable Cookies, you may not be able to
												access functionality on our website
												correctly or at all. We never place
												Personally Identifiable Information in
												Cookies, however, we have no control over
												any third party’s use of Cookies or Cookies
												that are embedded in You Tube videos which
												you may access from our site.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												INFORMATION FROM OTHER SOURCES
											</p>
											<p style={{ margin: '0' }}>
												Company may collect and retain public
												information provided by you to YouTube and
												other social media platforms.
											</p>
										</li>
									</ol>
								</li>
							</ul>
						</li>

						{/* 3. USE AND SHARING OF INFORMATION */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								USE AND SHARING OF INFORMATION
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										3.1 WHAT DOES COMPANY DO WITH COLLECTED
										INFORMATION?
									</p>
									<ol type='a'>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												OPERATING OUR SITE AND PERFORMING SERVICES
											</p>
											<p style={{ margin: '0' }}>
												We use your personal information to verify
												your identity, to check your qualifications,
												or to follow up with transactions initiated
												on the Site. We may also use your contact
												information to inform you of any changes to
												the Site, or to send you additional
												information about Company. If you give your
												permission during the account registration
												process, we may share your contact
												information with our business partners or
												other companies so that they may send you
												promotional materials. We use financial
												information to verify your qualifications
												for certain products or services and to
												process payments. We use demographic data to
												customize and tailor your experience on the
												Site, displaying content that we think you
												might be interested in and according to your
												preferences.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												TRAFFIC DATA
											</p>
											<p style={{ margin: '0' }}>
												We use traffic data to analyze our Site
												traffic, but we do not examine this
												information for individually identifying
												information. In addition, we may use IP
												addresses to help diagnose problems with our
												server, to administer the Site, or to
												display the content according to your
												preferences. Traffic and transaction
												information may also be shared with business
												partners and advertisers on an aggregate
												basis.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												USE OF COOKIES
											</p>
											<p style={{ margin: '0' }}>
												We may use cookies and other tracking
												technologies to deliver content specific to
												your interests, to save your password so you
												don't have to re-enter it each time you
												visit our Site, or for other purposes.
												Videos, Promotions or Advertisements
												displayed on our Site may contain cookies.
												We do not have access to or control over
												information collected by outside advertisers
												on our site.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												DISCLOSURE OF PERSONAL INFORMATION
											</p>
											<p style={{ margin: '0' }}>
												We may disclose personal information that
												you provide via the Site to the following
												third parties:
												<ul>
													<li>Our subsidiaries and affiliates;</li>
													<li>
														Joint venture or marketing partners
														who may want to send you information
														about their products or services,
														unless you have specifically requested
														that we not share your information for
														this purpose; and
													</li>
													<li>
														Contractors, vendors, and service
														providers we use to support our
														business and the Site. These support
														partners have all agreed to uphold the
														same standards of security and
														confidentiality that we provide to you
														under this Policy, and they have
														agreed to only use your information to
														carry out their specific business
														obligations to Company.
													</li>
												</ul>
												We may also disclose personal information
												that you provide via the Site in the event
												of a merger, acquisition, or any form of
												sale of some or all of our assets, in which
												case personal information we collect via the
												Site will be among the assets transferred to
												the buyer. Further, we may disclose your
												personal information to third parties to
												<ul>
													<li>
														Comply with any court order or other
														legal obligation;
													</li>
													<li>
														Enforce or apply our terms of use and
														other agreements; and
													</li>
													<li>
														Protect the rights, property, or
														safety of our business, our clients,
														customers, employees, or others. This
														includes exchanging information with
														other companies and organizations for
														the purposes of fraud protection,
														information security, and other
														related matters.
													</li>
												</ul>
											</p>
										</li>
									</ol>
								</li>
							</ul>
						</li>

						{/* 4. INTRODUCTION */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								USER CHOICES REGARDING COLLECTION, USE, AND DISTRIBUTION
								OF INFORMATION
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										4.1 ACCOUNT SETTINGS
									</p>
									<p style={{ margin: '0' }}>
										We maintain a procedure to help you confirm that
										your Personal Information remains correct and
										up-to-date. At any time, if you have an account
										with us, you may visit your personal profile at
										the web site address you were provided at the time
										your account was confirmed. Through your personal
										profile you may: (a) review and update your
										Personal Information that we have already
										collected; (b) choose whether you wish us to send
										you information about our company or promotional
										material from some of our partners; and (c) choose
										whether you wish for us to share your Personal
										Information with third parties.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										4.2 LOST OR STOLEN INFORMATION
									</p>
									<p style={{ margin: '0' }}>
										You must promptly notify us if your credit card,
										user name, or password is lost, stolen, or used
										without permission. In such an event, we will
										remove that credit card number, user name, or
										password from your account and update our records
										accordingly.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										4.3 COMMUNICATIONS
									</p>
									<p style={{ margin: '0' }}>
										<ul>
											<li>
												If you do not wish to receive information
												and promotional material from us or from
												some of our partners, you may select the
												appropriate "opt-out" option each time we
												ask you for information.
											</li>
											<li>
												You can opt-out of e-mails from us by
												clicking on the unsubscribe link in each
												message, or e-mailing us with UNSUBSCRIBE in
												the subject field of the e- mail.
											</li>
										</ul>
									</p>
								</li>
							</ul>
						</li>

						{/* 5. FOR RESIDENTS OF CALIFORNIA */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								FOR RESIDENTS OF CALIFORNIA
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										5.1 SHINE THE LIGHT
									</p>
									<p style={{ margin: '0', marginBottom: '15px' }}>
										Under California's "Shine the Light" law,
										California residents who provide personal
										information in obtaining products or services for
										personal, family, or household use are entitled to
										request and obtain from us, once each calendar
										year, information about the customer information
										we shared, if any, with other businesses for their
										own direct marketing uses. If applicable, this
										information would include the categories of
										customer information and the names and addresses
										of those businesses with which we shared customer
										information for the immediately preceding calendar
										year.
									</p>
									<p style={{ margin: '0', marginBottom: '15px' }}>
										To obtain this information, please send an e-mail
										message to admin@creatorship.biz with "Request for
										California Privacy Information" in the subject
										line and in the body of your message. We will
										provide in response the requested information to
										you at your e-mail address.
									</p>
									<p style={{ margin: '0' }}>
										Please be aware that not all information sharing
										is covered by the "Shine the Light" requirements
										and only required information will be included in
										our response.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										5.2 ERASER BUTTON
									</p>
									<p style={{ margin: '0' }}>
										Under California's "Eraser Button" law, California
										residents under age 18 who have registered to use
										the Site may request removal of content they have
										posted. To make a request, please send an e-mail
										message to admin@creatorship.biz with "Eraser
										Button" in the subject line and in the body of
										your message.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										5.3 The California Consumer Privacy Act ("CCPA")
									</p>
									<p style={{ margin: '0' }}>
										Provides additional rights to residents of
										California regarding our information collection
										and use practices.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										5.3.1 CATEGORIES OF PERSONAL INFORMATION COLLECTED
									</p>
									<p style={{ margin: '0' }}>
										We identify below the categories of personal
										information that we have collected about our users
										in the last 12 months, using the categories
										provided in the CCPA.
										<ul>
											<li>Identifiers</li>
											<li>Customer Records Information</li>
											<li>
												Characteristics of protected classifications
												under California or federal law
											</li>
											<li>Commercial Information</li>
											<li>
												Internet or Other Electronic Network
												Activity Information
											</li>
											<li>Geolocation Data</li>
											<li>
												Audio, Visual, Electronic, Thermal,
												Olfactory, or Similar Information
											</li>
											<li>
												Professional or Employment-related
												Information
											</li>
											<li>Educational Information</li>
											<li>Inferences</li>
										</ul>
										For more detail on the information we collect,
										including the sources we receive information from,
										please refer to our Information Collection
										Practices above. We collect and use these
										categories of personal information for the
										business purposes described in the Information
										Collection Practices section above.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										5.3.2 CATEGORIES OF PERSONAL INFORMATION SOLD
									</p>
									<p style={{ margin: '0' }}>
										Company does not sell personal information as
										defined under the CCPA.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										5.3.3 RIGHTS OF CALIFORNIA RESIDENTS:
									</p>
									<ol type='A'>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												Right to Know
											</p>
											<p style={{ margin: '0' }}>
												You have the right to know certain details
												about our data practices in the past 12
												months. In particular, you may request the
												following from us:
												<ul>
													<li>
														The categories of personal information
														we have collected about you;
													</li>
													<li>
														The categories of sources from which
														the personal information was
														collected;
													</li>
													<li>
														The categories of personal information
														about you we disclosed for a business
														purpose or sold;
													</li>

													<li>
														The categories of third parties to
														whom the personal information was
														disclosed for a business purpose or
														sold;
													</li>

													<li>
														The business or commercial purpose for
														collecting or selling the personal
														information; and
													</li>

													<li>
														The specific pieces of personal
														information we have collected about
														you.
													</li>
												</ul>
												To exercise the Right to Know, please send
												an e-mail message to admin@creatorship.biz
												with "California Right to Know" in the
												subject line and in the body of your
												message. In the request, please specify
												which right you are seeking to exercise and
												the scope of the request. We will confirm
												receipt of your request within 10 days. We
												may require specific information from you to
												help us verify your identity and process
												your request. If we are unable to verify
												your identity, we may deny your requests to
												know.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												Right to Delete
											</p>
											<p style={{ margin: '0' }}>
												You have the right to request that we delete
												the personal information we have collected
												from you in the last 12 months. To exercise
												the Right to Delete, please send an e-mail
												message to admin@creatorship.biz with
												"California Right to Delete" in the subject
												line and in the body of your message. We
												will confirm receipt of your request within
												10 days. We may require specific information
												from you to help us verify your identity and
												process your request. If we are unable to
												verify your identity, we may deny your
												request to delete. If we deny your request,
												we will explain the reason why in our
												response.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												Right to Opt-Out
											</p>
											<p
												style={{
													margin: '0',
													marginBottom: '15px',
												}}
											>
												You have the right to tell us not to sell
												your information at any time. To submit a
												request to opt-out, please send an e-mail
												message to admin@creatorship.biz with
												"California Right to Opt-Out" in the subject
												line and in the body of your message. We
												will confirm receipt of your request within
												10 days. We may require specific information
												from you to help us verify your identity and
												process your request. If we are unable to
												verify your identity, we may deny your
												request to delete. If we deny your request,
												we will explain the reason why in our
												response.
											</p>
											<p
												style={{
													margin: '0',
												}}
											>
												If you are a California resident and we know
												you are under 16 years of age, we will not
												sell your personal information unless (i)
												you are 13 years of age or more and have
												provided opt-in consent and confirmed that
												choice, or (ii) you are under 13 years of
												age and your parent or guardian has provided
												affirmative authorization of that sale. If
												you or your parent or guardian has provided
												opt-in consent, you can change your mind at
												any time by sending an e-mail message to
												admin@creatorship.biz with "California Right
												to Opt-Out" in the subject line and in the
												body of your message. We will confirm
												receipt of your request within 10 days. We
												may require specific information from you to
												help us verify your identity and process
												your request. If we are unable to verify
												your identity, we may deny your request to
												delete. If we deny your request, we will
												explain the reason why in our response.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												Right to Non-discrimination
											</p>
											<p style={{ margin: '0' }}>
												You have the right not to receive
												discriminatory treatment by us for
												exercising any of your rights.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												Authorized Agent
											</p>
											<p style={{ margin: '0' }}>
												You can designate an authorized agent to
												submit requests on your behalf. However, we
												will require written proof of the agent's
												permission to do so and verify your identity
												directly.
											</p>
										</li>
										<li>
											<p style={{ fontWeight: 'bold' }}>
												Notice of Financial Incentives
											</p>
											<p style={{ margin: '0' }}>
												Financial incentives are programs, benefits,
												or other offerings, including payments to
												consumers as compensation, for the
												disclosure, deletion, or sale of personal
												information about them. We do not offer any
												financial incentives.
											</p>
										</li>
									</ol>
								</li>
							</ul>
						</li>

						{/* 6. SECURITY */}
						<li>
							<p style={{ fontWeight: 'bold' }}>SECURITY</p>
							<p style={{ margin: '0' }}>
								The Site has security measures in place to prevent the
								loss, misuse, and alteration of the information that we
								obtain from you, but we make no assurances about our
								ability to prevent any such loss, misuse, to you or to
								any third party arising out of any such loss, misuse, or
								alteration.
							</p>
						</li>

						{/* 7. PUBLIC WEBSITE AREAS AND ACCESS TO OTHER WEBSITES */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								PUBLIC WEBSITE AREAS AND ACCESS TO OTHER WEBSITES
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										7.1 PUBLIC FORUMS
									</p>
									<p style={{ margin: '0' }}>
										The Site may include interactive forums such as
										message boards and chat rooms. Please remember
										that any information that is disclosed in these
										areas becomes public information and you should
										exercise caution when deciding to disclose your
										personal information.
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										7.2 OTHER WEBSITES
									</p>
									<p style={{ margin: '0' }}>
										The Site may contain links to other websites. If
										you choose to visit other websites, we are not
										responsible for the privacy practices or content
										of those other websites, and it is your
										responsibility to review the privacy policies at
										those websites to confirm that you understand and
										agree with their policies.
									</p>
								</li>
							</ul>
						</li>

						{/* 8. CONTACT INFORMATION AND POLICY UPDATES */}
						<li>
							<p style={{ fontWeight: 'bold' }}>
								CONTACT INFORMATION AND POLICY UPDATES
							</p>
							<ul>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										8.1 CONTACTING US
									</p>
									<p style={{ margin: '0' }}>
										If you have any questions about this Policy, our
										practices related to this Site, or if you would
										like to have us remove your information from our
										database, please feel contact us at the following
										address:
										<p style={{ paddingLeft: '15px' }}>
											<p style={{ margin: '0' }}>Creatorship</p>
											<p style={{ margin: '0' }}>
												360 E. 1st Street #4183
											</p>
											<p style={{ margin: '0' }}>Tustin, CA 92780</p>
											<p style={{ margin: '0' }}>(714) 464-7131</p>
											<p style={{ margin: '0' }}>
												admin@creatorship.biz
											</p>
										</p>
									</p>
								</li>
								<li>
									<p style={{ fontWeight: 'bold' }}>
										8.2 UPDATES AND CHANGES
									</p>
									<p style={{ margin: '0' }}>
										We reserve the right, at any time, to add to,
										change, update, or modify this Policy, simply by
										posting such change, update, or modification on
										the Site and without any other notice to you. Any
										such change, update, or modification will be
										effective immediately upon posting on the Site. It
										is your responsibility to review this Policy from
										time to time to ensure that you continue to agree
										with all of its terms.
									</p>
								</li>
							</ul>
						</li>
					</ol>
				</div>
			</div>
		</>
	);
}
