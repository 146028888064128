import React from 'react';

export default function Refund() {
	return (
		<>
			<div
				style={{
					backgroundColor: 'white',
					paddingTop: '15px',
					paddingBottom: '25px',
				}}
			>
				<h1
					style={{
						textAlign: 'center',
					}}
				>
					REFUND POLICY
				</h1>

				<div style={{ paddingLeft: '35px', paddingRight: '35px' }}>
					<p>
						<b>
							All sales are final, and Creatorship, LLC (herein either
							“the Company” or “Company”) does not offer any money-back
							guarantees.{' '}
						</b>
						You recognize and agree that you shall not be entitled to a
						refund for any purchase under any circumstances, whether
						purchased through its website at Creatorship.biz (herein “the
						Site” or “the Service”) or otherwise.
					</p>

					<p>
						<b>
							NO WARRANTIES. COMPANY HEREBY DISCLAIMS ALL WARRANTIES.{' '}
						</b>
						COMPANY IS MAKING THE SITE AVAILABLE "AS IS" WITHOUT WARRANTY
						OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS
						FROM USE OF, OR INABILITY TO USE, THE SITE OR THE SERVICE. TO
						THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY EXPRESSLY
						DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED,
						REGARDING THE SITE, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
						WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
						PURPOSE, OR NONINFRINGEMENT. COMPANY DOES NOT WARRANT THAT THE
						SITE OR THE SERVICE WILL MEET YOUR REQUIREMENTS OR THAT THE
						OPERATION OF THE SITE OR THE SERVICE WILL BE UNINTERRUPTED OR
						ERROR-FREE.
					</p>
					<p>
						<b>
							LIMITED LIABILITY. COMPANY'S LIABILITY TO YOU IS LIMITED.{' '}
						</b>
						TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
						COMPANY BE LIABLE FOR DAMAGES OF ANY KIND (INCLUDING, BUT NOT
						LIMITED TO, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES,
						LOST PROFITS, OR LOST DATA, REGARDLESS OF THE FORESEEABILITY
						OF THOSE DAMAGES) ARISING OUT OF OR IN CONNECTION WITH YOUR
						USE OF THE SITE OR ANY OTHER MATERIALS OR SERVICES PROVIDED TO
						YOU BY COMPANY. This limitation shall apply regardless of
						whether the damages arise out of breach of contract, tort, or
						any other legal theory or form of action.
					</p>
				</div>
			</div>
		</>
	);
}
