import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from './drawer-pages/About';
import Contact from './drawer-pages/Contact';
import PageNotFound from './drawer-pages/PageNotFound';
import PrivacyPolicy from './foot/PrivacyPolicy';
import Terms from './foot/Terms';
import Refund from './foot/Refund';
import HomeThree from './drawer-pages/HomeThree';

export default function RouteNavigator() {
	return (
		<Router>
			<Routes>
				<Route path='/' element={<HomeThree />} />
				<Route path='/about' element={<About />} />
				<Route path='/contact' element={<Contact />} />
				<Route path='/refunds' element={<Refund />} />
				<Route path='/terms' element={<Terms />} />
				<Route path='/privacy' element={<PrivacyPolicy />} />
				<Route path='*' element={<PageNotFound />} />
			</Routes>
		</Router>
	);
}
