import React from 'react';
import {
	Box,
	List,
	Card,
	ListItem,
	Typography,
	CardContent,
	ListItemText,
	ListItemIcon,
	Grid,
} from '@mui/material';
import ArrowIcon from '@mui/icons-material/ChevronRight';

export default function About() {
	return (
		<Box className='sliding-up' sx={{ marginTop: '30px' }}>
			<Card>
				<CardContent>
					<Box pb={5}>
						<Typography
							variant='h3'
							align='center'
							fontFamily={'Permanent Marker, cursive'}
						>
							About Us
						</Typography>
						<Box padding={5}>
							<Typography variant='h5'>
								Our background and interests encompass the following:
							</Typography>
							<Grid container spacing={2} columns={16}>
								<Grid item xs={8}>
									<List>
										<ListItem></ListItem>
										<ListItem>
											<ListItemIcon>
												<ArrowIcon />
											</ListItemIcon>
											<ListItemText>
												Full Stack Applications
											</ListItemText>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<ArrowIcon />
											</ListItemIcon>
											<ListItemText>
												Artificial Intelligence (AI)
											</ListItemText>
										</ListItem>
									</List>
								</Grid>
								<Grid item xs={8}>
									<List>
										<ListItem></ListItem>
										<ListItem>
											<ListItemIcon>
												<ArrowIcon />
											</ListItemIcon>
											<ListItemText>Creative Writing</ListItemText>
										</ListItem>
										<ListItem>
											<ListItemIcon>
												<ArrowIcon />
											</ListItemIcon>
											<ListItemText>
												User Experience (UX)
											</ListItemText>
										</ListItem>
									</List>
								</Grid>
							</Grid>
						</Box>
						<Box sx={{ textAlign: 'center' }}>
							<Typography fontSize={'large'}>
								We look forward to meeting your needs
							</Typography>
							<Typography>-Creatorship™</Typography>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
