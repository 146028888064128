import React from 'react';
import { Box, Grid, Link, Typography } from '@mui/material';

export default function Footer() {
	return (
		<Box mt={5} display={'flex'} justifyContent={'center'}>
			<Grid container>
				<Typography align='center' variant='span'>
					Copyright © 2023 Creatorship, LLC. All rights reserved. |
				</Typography>
				<Link ml={0.5} href='/privacy'>
					Privacy Policy
				</Link>
				<Typography variant='span' mx={0.5}>
					|
				</Typography>
				<Link href='/terms'>Terms & Conditions</Link>
				<Typography variant='span' mx={0.5}>
					|
				</Typography>
				<Link href='/refunds'>Refund Policy</Link>
			</Grid>
		</Box>
	);
}
