import React from 'react';
import { Box, Grid } from '@mui/material';
import PhaseThreeLanding from '../content-sections/PhaseThreeLanding';

export default function HomeThree() {
	return (
		<Box sx={{ marginTop: '30px' }}>
			<Grid container spacing={3} display='flex' justifyContent='center'>
				<Grid item xs={12}>
					<PhaseThreeLanding />
				</Grid>
			</Grid>
		</Box>
	);
}
