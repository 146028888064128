export const cardHead = {
	textAlign: 'center',
	color: 'white',
	backgroundImage: 'linear-gradient(to bottom right, #00997a, #006442)',
	// 'linear-gradient(#00997a, #006442)',
	// 'linear-gradient(#00997a, #006442)',
	// 'linear-gradient(#006442, #00997a)',
	// 'linear-gradient(to bottom right, #006442, #064E40)',
	// 'linear-gradient(to bottom right, #30443D, #006442)',

	// backgroundColor: '#006442',
};
