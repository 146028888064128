import React, { useState, useRef } from 'react';
import {
	Box,
	Card,
	CardHeader,
	CardContent,
	Typography,
	Grid,
	FormControl,
	FormControlLabel,
	FormLabel,
	RadioGroup,
	Radio,
	TextField,
	Button,
} from '@mui/material';
import emailjs from '@emailjs/browser';
import { cardHead } from '../../../utils/style/theme';

export default function ContactUs() {
	const [showForm, setShowForm] = useState(true);
	const [msg, setMsg] = useState('');
	const [form, setForm] = useState({
		priorContact: 'yes',
		screenName: '',
		userCategory: 'General',
		email: '',
		message: '',
		missingInfo: 'yes',
		missingInfoNotes: '',
	});
	const jsForm = useRef();

	const backButton = () => {
		setForm({
			priorContact: 'no',
			screenName: '',
			userCategory: 'General',
			email: '',
			message: '',
			missingInfo: 'yes',
			missingInfoNotes: '',
		});
		setMsg('');
		setShowForm(true);
	};

	const submitButton = () => {
		console.log(jsForm.current);
		setShowForm(false);
		setMsg('Please Wait...');
		submitEmailJS();
	};

	const submitEmailJS = async () => {
		emailjs
			.sendForm(
				'service_oi3x10c',
				'template_38pjfh6',
				jsForm.current,
				'NGJlRfDXbWKwBHfMI'
			)
			.then(
				(result) => {
					console.log(result.text);
					setMsg('Message Sent!');
				},
				(error) => {
					console.log(error.text);
					setMsg('An error occurred, please try again later');
				}
			);
	};

	return (
		<Box>
			<Card>
				<CardHeader title='✉️ Contact Us' sx={cardHead} />
				<CardContent>
					{showForm ? (
						<form ref={jsForm}>
							<Grid container spacing={1} sx={{ textAlign: 'center' }}>
								{/* Prior Contact */}
								<Grid item xs={12}>
									<FormControl>
										<FormLabel id='demo-row-radio-buttons-group-label'>
											Have you been in contact with us before?
										</FormLabel>
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											name='row-radio-buttons-group'
											sx={{
												display: 'flex',
												justifyContent: 'center',
											}}
											value={form.priorContact}
											onChange={(e) => {
												setForm({
													...form,
													priorContact: e.target.value,
												});
											}}
										>
											<FormControlLabel
												name='priorContact'
												value='yes'
												control={<Radio />}
												label='Yes'
											/>
											<FormControlLabel
												name='priorContact'
												value='no'
												control={<Radio />}
												label='No'
											/>
										</RadioGroup>
									</FormControl>
								</Grid>

								{/* Names */}
								<Grid item xs={6}>
									<TextField
										fullWidth
										required
										name='name'
										label={'Name'}
										value={form.screenName}
										onChange={(e) => {
											setForm({
												...form,
												screenName: e.target.value,
											});
										}}
									/>
								</Grid>

								{/* Email */}
								<Grid item xs={6}>
									<TextField
										fullWidth
										required
										name='email'
										label={'Email'}
										value={form.email}
										onChange={(e) => {
											setForm({
												...form,
												email: e.target.value,
											});
										}}
									/>
								</Grid>

								{/* Message */}
								<Grid item xs={12}>
									<TextField
										fullWidth
										multiline
										required
										name='message'
										label='Message'
										value={form.message}
										onChange={(e) => {
											setForm({
												...form,
												message: e.target.value,
											});
										}}
									/>
								</Grid>

								{/* Buttons */}
								<Grid item xs={12}>
									<Button
										variant='contained'
										disabled={
											!form.screenName ||
											!form.email ||
											!form.message
										}
										onClick={() => {
											submitButton();
										}}
									>
										Submit
									</Button>
								</Grid>
							</Grid>
						</form>
					) : (
						<>
							{msg ? (
								<>
									<Button onClick={() => backButton()}>Back</Button>
									<Typography align='center' fontStyle='italic'>
										{msg}
									</Typography>
								</>
							) : (
								<Typography align='center' fontStyle='italic'>
									Loading...
								</Typography>
							)}
						</>
					)}
				</CardContent>
			</Card>
		</Box>
	);
}
