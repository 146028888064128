import React from 'react';
import {
	Box,
	Card,
	CardHeader,
	Typography,
	CardContent,
	Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { cardHead } from '../../utils/style/theme';

export default function PageNotFound() {
	let navigate = useNavigate();

	return (
		<Box sx={{ marginTop: '30px' }}>
			<Card>
				<CardHeader title='404' sx={cardHead} />
				<CardContent>
					<Box px={15} pb={5}>
						<Typography align='center' variant='h5' py={5}>
							Page Not Found
						</Typography>
						<Typography m={3} align='center' fontStyle={'italic'}>
							If This Is A Mistake Please Let Us Know:
						</Typography>
						<Box display={'flex'} justifyContent={'center'}>
							<Button
								variant='contained'
								onClick={() => {
									navigate('/contact');
								}}
							>
								Contact
							</Button>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</Box>
	);
}
