import React from 'react';
import PropTypes from 'prop-types';
import {
	Box,
	Drawer,
	CssBaseline,
	IconButton,
	List,
	Link,
	ListItem,
	ListItemIcon,
	ListItemText,
	Toolbar,
	AppBar,
	Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import RouteNavigator from './RouteNavigator';
import Footer from './foot/Footer';

const drawerWidth = 175;

ResponsiveDrawer.propTypes = {
	/**
	 * Injected by the documentation to work in an iframe.
	 * You won't need it on your project.
	 */
	window: PropTypes.func,
};

export default function ResponsiveDrawer(props) {
	const { window } = props;
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const linkDrawer = (
		<div>
			<List>
				<Link
					href='/'
					sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}
				>
					<ListItem>
						<ListItemIcon>
							<HomeIcon />
						</ListItemIcon>
						<ListItemText primary='Home' />
					</ListItem>
				</Link>
				<Link
					href='/about'
					sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}
				>
					<ListItem>
						<ListItemIcon>
							<InfoIcon />
						</ListItemIcon>
						<ListItemText primary='About' />
					</ListItem>
				</Link>
				<Link
					href='/contact'
					sx={{ textDecoration: 'none', color: 'rgba(0, 0, 0, 0.54)' }}
				>
					<ListItem>
						<ListItemIcon>
							<ContactMailIcon />
						</ListItemIcon>
						<ListItemText primary='Contact' />
					</ListItem>
				</Link>
			</List>
		</div>
	);

	const container =
		window !== undefined ? () => window().document.body : undefined;

	return (
		<Box
			sx={{
				display: 'flex',
				backgroundColor: '#E5E5E5',
				height: '100%',
				minHeight: '100vh',
			}}
		>
			<CssBaseline />

			<AppBar
				position='fixed'
				sx={{
					width: { sm: `calc(100% - ${drawerWidth}px)` },
					ml: { sm: `${drawerWidth}px` },
					backgroundImage:
						'linear-gradient(to bottom right, #00997a, #006442)',
				}}
			>
				<Toolbar>
					<IconButton
						color='inherit'
						aria-label='open drawer'
						edge='start'
						onClick={handleDrawerToggle}
						sx={{ mr: 2, display: { sm: 'none' } }}
					>
						<MenuIcon />
					</IconButton>
					<Link
						href='/'
						sx={{ textDecoration: 'none', color: 'rgb(256,256,256)' }}
					>
						<Typography
							p={1}
							variant='h3'
							sx={{ fontFamily: 'Oooh Baby, cursive' }}
						>
							Creatorship
						</Typography>
					</Link>
					<Typography pb={3}>™</Typography>
				</Toolbar>
			</AppBar>

			<Box
				component='nav'
				sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
			>
				{/* The implementation can be swapped with js to avoid SEO duplication of links. */}
				<Drawer
					container={container}
					variant='temporary'
					open={mobileOpen}
					onClose={handleDrawerToggle}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
				>
					{linkDrawer}
				</Drawer>
				<Drawer
					variant='permanent'
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': {
							boxSizing: 'border-box',
							width: drawerWidth,
						},
					}}
					open
				>
					{linkDrawer}
				</Drawer>
			</Box>
			<Box
				component='main'
				mb={'30px'}
				sx={{
					p: 3,
					flexGrow: 1,
					// 100 switched to 98 below... windows pc rendered differently...
					width: { sm: `calc(98% - ${drawerWidth}px)` },
					minWidth: `calc(98vw - ${drawerWidth}px)`,
					backgroundColor: '#E5E5E5',
				}}
			>
				<Toolbar />
				<RouteNavigator />
				<Footer />
			</Box>
		</Box>
	);
}
